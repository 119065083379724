import { ActionType } from 'typesafe-actions'
import { AppType, TenantType } from '~/types/enums'
import * as actions from './actions'

export type ViewAction = ActionType<typeof actions>

export type ViewState = {
  tenantType: TenantType
  isApp: boolean
  appInfo?: {
    os: string
    appVersion: string
    appKey: string
    appType: AppType
  }
  isMobile: boolean
  languageType: 'Korean' | 'English' | 'Chinese'
  isAndroid: boolean
  isIE: boolean
  browserVersion?: string
  firstLoaded: boolean
  firstLoadFailed: boolean
  isPrepareOrderProcessing: boolean
  title: string
  headerCloseUrl?: string
  showingAppHeader: boolean
  layerData: LayerData[]
  isShippingFeeLoadedAfterChanged: boolean // 선택 변경시 배송비 조회까지 완료되었는지 여부. 배송비 조회까지 완료된 후에 상태가 변경되어야 하는 애들(게이지바 같은..) 때문에 존재한다.
}

export type AddressTabType = 'Recent' | 'New'

export type OpenLayerPayloadType = {
  type: EnumLayerType
  detailData: LayerDetailData
}

export type LayerData = {
  type: EnumLayerType
  key: string
}

export type AlertData = {
  title?: string
  message: string
  confirmText?: string
  onClose: () => void
}

export type ConfirmData = {
  title?: string
  message: string
  confirmText?: string
  cancelText?: string
  onClose: (result: boolean) => void
}

export enum EnumLayerType {
  UnitCouponBox, // G,G9용
  GroupCouponBox, // 옥션용
  CountrySelector,
  JoinSmileClubForSmileDelivery,
  SmileClubOneClickJoin,
  NudgingClubOneClick,
  NudgingSmileCard,
  NudgingAppDown,
  ConfirmAuthentication,
  ConfirmOrderExceptSomeItems,
  ConfirmIncomeDutyItemOrderType,
  EstimateSheet,
  OverseaPackageNoti,
  ConfirmMaxInstallOrder,
  NudgingCartCountExtended,
  NudgingUniverseClubAutoRenewal,
}
