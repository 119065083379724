import siteEnv from '~/lib/site-env'
import { TenantConstants } from './index'

const constants: TenantConstants = {
  NotUseMobileVersionStaticPages: [],
  IsOverseaShippingAvailable: true,
  IsShowingBenefitInfos: true,
  PageShowingPoint: 'OnlyEssential',
  CouponUseType: 'UnitCoupon',
  IsUsingCouponBoxLayer:
    siteEnv.languageType === 'Korean' || siteEnv.deviceType === 'PC',
  IsUsingCartTab: siteEnv.languageType === 'Korean',
  IsUsingCartBt:
    siteEnv.languageType === 'Korean' && siteEnv.deviceType === 'Mobile',
  ElementScrollOptions: {},
  SupportedNudgingTypes:
    siteEnv.deviceType === 'Mobile' && siteEnv.languageType === 'Korean'
      ? [
          'CLUB_ONE_CLICK',
          'SMILE_CARD',
          'CART_COUNT_EXTENDED',
          'UNIVERSE_CLUB_AUTO_RENEWAL_NUDGE',
        ]
      : siteEnv.languageType === 'Korean'
      ? ['CART_COUNT_EXTENDED', 'UNIVERSE_CLUB_AUTO_RENEWAL_NUDGE']
      : [],
  SFExpressUnableLargeCategoryCodes: [
    '100000005', // 화장품
    '100000071', // 바디/헤어
  ],
  ExpressShopSellerIds: {
    Unknown: '',
    Homeplus: 'SHomeplus',
    LotteSuper: 'lottesuper',
    GSFresh: 'gsfresh300',
    LotteMart:
      siteEnv.env === 'production' || siteEnv.env === 'av'
        ? 'lottemartm'
        : 'testlotte',
  },
  MomentLocale:
    siteEnv.languageType === 'English'
      ? 'en'
      : siteEnv.languageType === 'Chinese'
      ? 'zh-cn'
      : 'ko',
  CustomerCenterContact: '1566-5701',
}

export default constants
